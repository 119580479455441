<template>
  <div class="show_area">
    <div class="member_show">
      <div class="show_title">
        <img src="@/assets/img/account/icon_title_4.svg" alt="">
        <h4 class="txt-bold">追蹤清單</h4>
      </div>

      <div class="show_track">
        <div class="cart_head">
          <div class="cart_tr txt-grey">
            <div class="cart_td td-pic"><small>商品</small></div>
            <div class="cart_td td-name"></div>
            <div class="cart_td td-prize txt-center"><small>單價</small></div>
            <div class="cart_td td-add txt-center"><small>前往商品頁</small></div>
            <div class="cart_td td-del txt-center"><small>移除</small></div>
          </div>
        </div>

        <div class="cart_item_box">
          <div
            v-for="(item, key) in trackingData"
            :key="`tracking${key}`"
            class="cart_item">
              <div class="cart_tr">
                <div class="cart_td td-pic">
                  <div class="picbox">
                    <figure
                      class="cart_pic"
                      :style="`background-image: url(${item.cover})`">
                    </figure>
                  </div>
                </div>
                <div class="cart_td td-name">
                  <div class="tag small">{{item.category}}</div>
                  <h6 class="txt-bold">{{item.name}}</h6>
                  <small class="flow txt-greenlake">{{item.flowType}}</small>
                  <!-- <small class="dis_tag txt-white">
                    輸入 wiho123 可享有每日$123優惠價&nbsp;
                    <img
                      src="@/assets/img/icon/icon_exclamation-w.svg"
                      alt=""
                      style="width: .65em;">
                  </small> -->
                </div>
                <div class="cart_td td-prize center" data-rwd="單價" >
                  <div>
                    <p class="txt-redorange">
                      ${{item.sell_price}} / 天
                    </p>
                    <p
                      v-if="item.price"
                      :class="{'txt-line-through': item.sell_price}">
                        ${{item.price}} / 天
                    </p>
                  </div>
                </div>
                <div
                  class="cart_td td-add center"
                  data-rwd="前往商品頁" >
                    <img
                      class="cart_add"
                      src="@/assets/img/account/icon_shoppingcar.svg"
                      alt=""
                      @click="$router.push({
                        name: 'ProductInfo',
                        params: {
                          id: item.id
                        },
                      });">
                </div>
                <div class="cart_td td-del center" data-rwd="移除" >
                  <img
                    class="cart_del"
                    @click="updateFav(item.id)"
                    src="@/assets/img/shopping/icon_trash.svg" alt="">
                </div>
                <a
                  @click="$router.push({
                    name: 'ProductInfo',
                    params: {
                      id: item.id
                    },
                  });"
                  class="btn btn-cyan_line">
                    前往商品頁
                </a>
                <img
                  src="@/assets/img/icon/icon_X2.svg"
                  @click="updateFav(item.id)"
                  class="close"
                  alt="">
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import requestApi from '@/lib/http/index';

export default {
  name: 'MemberTrack',
  components: {
  },
  data() {
    return {
      trackingData: [],
    };
  },
  computed: {
    ...mapState([
      'user',
    ]),
  },
  methods: {
    async getTrackingList() {
      this.trackingData = [];
      const { status, message, data } = await requestApi('member.getTrackingList');
      if (!status) {
        this.$customSWAL({
          icon: 'error',
          title: message,
        });
      }
      if (data) {
        data.forEach((element) => {
          this.trackingData.push(element);
        });
      }
    },
    async updateFav(id) {
      if (!this.user.id) {
        this.$customSWAL({
          icon: 'error',
          title: '請先登入',
        });
      } else {
        const { status, message } = await requestApi('member.addFavorite', {
          member_id: this.user.id,
          product_id: id,
        });

        if (status) {
          this.$customSWAL({
            title: message,
          });
        }
      }

      this.getTrackingList();
    },
  },
  mounted() {
    this.getTrackingList();
  },
};
</script>
